import React, {useState, useEffect} from 'react'
import styled from 'styled-components'
import {Modal} from 'react-bootstrap'

import { useTamanhoTela } from '../hooks/useTamanhoTela'


const ModalStyled = styled(Modal)`
  background-color: rgba(12,60,100,0.75);
  backdrop-filter: blur(2px);
  padding-top: ${props => props.mobile ? '55%' : '15%' };
`

function ModalServices(props){
  const [open, setOpen] = useState(props.open)

  const {isMobile, isTablet} = useTamanhoTela()

  return (
    <ModalStyled
      size='lg'
      show={open}
      onHide={() => setOpen(false)}
      mobile={isMobile||isTablet}
    >
      <Modal.Header closeButton>
        <Modal.Title>{props.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {props.text}
      </Modal.Body>
    </ModalStyled>
  )
}

export {ModalServices}
export default ModalServices