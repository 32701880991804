import React from "react"
import styled from "styled-components"

import { useTamanhoTela } from '../hooks/useTamanhoTela'

import MainBannerImg from '../assets/images/main-banner.png'
import MainBannerImgMobile from '../assets/images/main-banner-mobile.png'


const MainBannerStyled = styled.div`
  height: 75vh;
  /* width: 100%; */
  background-size: cover;
  background-image: url(${(props) => props.isMobile ? MainBannerImgMobile : MainBannerImg });
  background-repeat: no-repeat;
  padding: 2% 10%;

  @media only screen and (min-width: 1600px) {
    padding:5% 15%;
    height: 65vh;
  }

  @media only screen and (max-width: 600px) {
    height: 60vh;
    padding: 2% 0;
  }

`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 30%;
  align-items: center;
  justify-content: center;
  margin-top: 4%;
  margin-left: 11%;

  @media only screen and (max-width: 600px) {
    flex-wrap: wrap;
    width: 100%;
    margin-top: 18%;
    margin-left: 0%;
    
  }
`

const SiteTitle = styled.h1`
  text-shadow: 1px 1px #000000;
  color: #ffff;
  text-align: center;
  width: 700px;
  font-size: 48px;
  font-family: 'Mont Bold';

  @media only screen and (min-width: 1600px) {
    font-size:56px;
    width: 800px;
  }

  @media only screen and (max-width: 600px) {
    font-size:32px;
    width: 100%;
  }
`

const MainSubTitle = styled.h4`
  color: #ffff;
  font-size: 17px;
  width: 800px;
  /* text-align: left; */
  /* padding-left: 20px; */
  text-align: center;

  @media only screen and (min-width: 1600px) {
    font-size: 20px;
  }

  @media only screen and (max-width: 600px) {
    font-size:15px;
    width: 100%;
  }

`

const BtnBudget = styled.a`
  all: unset;
  background-color: #ff9900;
  border-radius: 30px;
  padding: 16px;
  color: #fff;
  cursor: pointer;
  text-align: center;
  margin-top: 20%;
  text-transform: uppercase;
  
  :hover{
    background-color: #c97b05;
    color: #fff;
  }
`


function MainBanner () {

  const {isMobile, isTablet, isDesktopLarge, isDesktop} = useTamanhoTela()

  return (
    
      <MainBannerStyled id='home' large={isDesktopLarge} isMobile={isMobile||isTablet}>

        <Container>

          <SiteTitle large={isDesktopLarge}> Sujou? <br/>A Very Clean limpa para si.</SiteTitle>
          <MainSubTitle>
            Temos a solução completa de higienização para vossos estofos e tapetes.
          </MainSubTitle>
          <BtnBudget
            target='_blank' 
            href='https://wa.me/message/RCV4UXG46YG5A1'
          >
            Quero solicitar um orçamento
          </BtnBudget>

        </Container>

      </MainBannerStyled>
 
  )
}

export {MainBanner}
export default MainBanner