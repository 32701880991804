import React from 'react'

import styled, {keyframes} from 'styled-components'

import Whats from '../assets/images/logo_whats.png'

const VibrateAnimation = keyframes`
    0% {margin-left: 0;}
    8.333% {margin-left: 5px;}
    16.667% {margin-left: 0;}
    25% {margin-left: -5px;}
    33.333% {margin-left: 0;}
    100% {margin-left: 0px;}
`

const WhatsAppBtnStyled = styled.a`
  background-color: #25d366;
  width: 60px;
  height: 60px;
  position: fixed;
  top: 89%;
  left: 93%;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: 300ms;

  :hover {
    background-color: #0a913b;
  }

  animation: ${VibrateAnimation} 2s ease-in infinite;

  @media only screen and (max-width: 600px) {
    top: 90%;
    left: 80%;
  }
`
const WhatsIcon = styled.img`
  width: 40px;
  height: 40px;
`


const WhatsAppBtn = () => {

  return(
   
   <WhatsAppBtnStyled
    target='_blank' 
    href='https://wa.me/message/RCV4UXG46YG5A1'
   >
      <WhatsIcon src={Whats}/>
   </WhatsAppBtnStyled>

  )
}

export default WhatsAppBtn