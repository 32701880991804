import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import AOS from "aos"
import "aos/dist/aos.css"

import { useTamanhoTela } from '../hooks/useTamanhoTela'

import { Card } from '.'

import sofa from '../assets/images/sofa.png'
import colchoes from '../assets/images/colchoes.png'
import tapetes from '../assets/images/tapetes.png'
import estofosPele from '../assets/images/estofos-pele.png'
import impermeabilizacao from '../assets/images/impermeabilizacao.png'
import alcatifas from '../assets/images/alcatifas.png'


import { 
  textoSofas, 
  textoColchoes, 
  textoTapetes,
  textoEstofosPele,
  textoImpermeabilizacao,
  textoAlcatifas
} from '../assets/texts'

import {
  ModalService1
}

from './index'

const Section = styled.section`
  width: 100%;
  height: 100%;
  background-color: #fefefe;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  /* margin: 36px 0px; */
  /* padding-bottom: 36px; */
  color: #0c3c64;
`

const  TitleContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 32px;
`

const SectionTitle = styled.h2`
  /* background-color: #fefefe; */
  /* width: 30%; */
  color: #0c3c64;
  text-align: center;
  font-size: 96px;

  font-family: 'Mont Bold';

  @media only screen and (max-width: 600px) {
    justify-content: center;
    margin-right: 0px;
    font-size: 78px;

  }
`

const MarkedText = styled.span`
  all: unset;
  background-color: #0d3c64;
  color: #fefefe;
`

// const SectionTitle = styled.h2`
//   /* background-color: #fefefe; */
//   /* width: 30%; */
//   text-align: center;
//   /* border-radius: 12px; */
//   padding: 8px;
//   border-bottom: 2px solid #0c3c64;
//   /* box-shadow: -1px 7px 14px 5px rgba(0,0,0,0.75); */
//   margin-top: ${props => props.mobile ? '0' : '32px'};
//   font-family: 'Mont Bold';
// ` 


const ServicesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: ${props => props.mobile ? 'center' : 'center'};
  width: 80%;
  margin-top: 64px;
  margin-bottom: 16px;

  
  @media only screen and (max-width: 600px) {
    margin-top: 16px;
  }
`


function ServicesSection(){
  const {isMobile, isTablet} = useTamanhoTela()

  const [openModal1, setOpenModal1] = useState(false)



  useEffect(() => {
    AOS.init({duration: 1000})
  },[])

  return (
    <>
      <ModalService1 open={openModal1}/>

      <Section id='servicos'>
        <TitleContainer data-aos="fade-up-right">
          <SectionTitle>
            Nossos 
            <br/> <MarkedText>serviços</MarkedText>
          </SectionTitle>
        </TitleContainer>

        <ServicesContainer
          mobile={isMobile||isTablet}
          data-aos="fade-left"
        >
          <Card
            img={sofa}
            title='Limpeza e higienização de sofá'
            text={textoSofas}
            onClick={(openModal1) => setOpenModal1(!openModal1)}
          />
          <Card
            img={colchoes}
            title='Limpeza e higienização de colchões'
            text={textoColchoes}
          />
          <Card
            img={tapetes}
            title='Lavagem e higienização de tapetes'
            text={textoTapetes}
          />
          <Card
            img={estofosPele}
            title='Limpeza de estofos em pele'
            text={textoEstofosPele}
          />
          <Card
            img={impermeabilizacao}
            title='Impermeabilização de estofos'
            text={textoImpermeabilizacao}
          />

          <Card
            img={alcatifas}
            title='Limpeza de Alcatifas'
            text={textoAlcatifas}
          />

        </ServicesContainer>
      </Section>
    </>
  )
}

export {ServicesSection}
export default ServicesSection