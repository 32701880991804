import React, {useState} from 'react'
import styled from 'styled-components'
import {Modal} from 'react-bootstrap'

import { useTamanhoTela } from '../hooks/useTamanhoTela'


const ModalStyled = styled(Modal)`
  background-color: rgba(12,60,100,0.75);
  backdrop-filter: blur(2px);
  padding-top: ${props => props.mobile ? '55%' : '15%' };
`

function ModalService1(props){
  const [open, setOpen] = useState(props.open)

  const {isMobile, isTablet} = useTamanhoTela()

  return (
    <ModalStyled
      size='lg'
      show={open}
      onHide={() => setOpen(false)}
      mobile={isMobile||isTablet}
    >
      <Modal.Header closeButton>
        <Modal.Title>Limpeza e higienização de sofá</Modal.Title>
      </Modal.Header>
      <Modal.Body>
      A Higienização é indicada para todos os tipos de sofás, cadeiras e poltronas e envolve uma limpeza profunda com o uso de produtos profissionais. Assim, conseguimos remover nódoas, eliminar sujidades orgânicas e inorgânicas presentes em vosso estofo, além de deitar para fora ácaros, fungos e bactérias que se reproduzem diariamente no tecido.
      </Modal.Body>
    </ModalStyled>
  )
}

export {ModalService1}
export default ModalService1