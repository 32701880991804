import React, {useEffect} from 'react'
import styled from 'styled-components'
import AOS from "aos"
import "aos/dist/aos.css"

import { useTamanhoTela } from '../hooks/useTamanhoTela'


const Section = styled.section`
  width: 100%;
  height: 70%;
  background-color: #fefefe;
  display: flex;
  justify-content: center;
  /* align-items: flex-start; */
  margin-bottom: 256px;


  @media only screen and (max-width: 600px) {
    justify-content: center;
  }
`

const Container = styled.div`
  display: flex;
  justify-content: center;
  
  width: 62%;

  @media only screen and (max-width: 600px) {
    flex-wrap: wrap;
    width: 100%;

  }

`

const  TitleContainer = styled.div`
  display: flex;
  justify-content: center;
  /* align-items: flex-start; */
  width: 50%;
  /* margin-left: 200px; */

  @media only screen and (max-width: 600px) {
    justify-content: center;
    margin-right: 0px;
    width: 100%;

  }
`

const SectionTitle = styled.h2`
  /* background-color: #fefefe; */
  /* width: 30%; */
  color: #0c3c64;
  text-align: right;
  font-size: 118px;

  font-family: 'Mont Bold';

  @media only screen and (max-width: 600px) {
    justify-content: center;
    margin-right: 0px;
    font-size: 78px;

  }
` 

const AboutContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  width: 50%;
  height: 100%;
  /* margin-top: ${props => props.mobile ? '16px' : '64px'}; */
  /* padding-right: 358px; */

  
  @media only screen and (max-width: 600px) {
    width: 80%;
    flex-direction: column;
  }
`

const Paragraf = styled.p`
  color: #0c3c64;
  padding: 16px 0;
  font-size: 18px;
  text-align: justify;
`

const MarkedText = styled.span`
  all: unset;
  background-color: #0d3c64;
  color: #fefefe;
`

function AboutSection(){
  const {isMobile, isTablet, isDesktop, isDesktopLarge} = useTamanhoTela()

  useEffect(() => {
    AOS.init({duration: 1000})
  },[])

  return (
    <Section id='sobre'>
      
      <Container>
      <TitleContainer>
        <SectionTitle data-aos="fade-up-left">
          Sobre 
          <br/> <MarkedText>nós</MarkedText>
        </SectionTitle>
      </TitleContainer>


      <AboutContainer
        mobile={isMobile||isTablet}
        data-aos="zoom-in"
      >
        <Paragraf>
          A Very Clean é uma empresa que atua no mercado da higiene desde 2021 e preocupamo-nos com o bem-estar da sua família. Acreditamos que a limpeza de um estofado vai muito além da questão estética. Envolve principalmente a saúde e o bem-estar.
        </Paragraf>

        <Paragraf>
          A nossa missão é proporcionar saúde a todos na sua casa através de um trabalho profissional de cuidado com estofos e artigos de decoração em tecido que, ao longo do uso, acabam por acumular ácaros, fungos e bactérias e outras sujidades.
        </Paragraf>

        <Paragraf>
          A nossa visão é profissionalismo, respeito e cuidado com o nosso cliente. Sempre trabalhando como se fosse para nós. 
          Os nossos valores são compromisso, honestidade e bons princípios para que o nosso serviço o possa surpreender do início ao fim.
        </Paragraf>
        

      </AboutContainer>

      </Container>
    </Section>
  )
}

export {AboutSection}
export default AboutSection