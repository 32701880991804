import React from 'react'
import styled from 'styled-components'

import {ReactComponent as StarIcon} from '../assets/icons/star.svg'

const CardContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  width: 345px;
  border: 1px solid #fefefe;
  border-radius: 10px ;
`

const Img = styled.img`
  width: 100%;
  border-radius: 8px 8px 0 0;
`


function BriefCard (props) {

  return (
    <CardContainer>
      <Img src={props.img}/>
    </CardContainer>
  )

}

export {BriefCard}
export default BriefCard