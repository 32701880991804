export const textoSofas = 'A Higienização é indicada para todos os tipos de sofás, cadeiras e poltronas e envolve uma limpeza profunda com o uso de produtos profissionais. Assim, conseguimos eliminar sujidades orgânicas e inorgânicas presentes em seu estofo, além de deitar para fora ácaros, fungos e bactérias que se reproduzem diariamente no tecido.'

export const textoColchoes = 'Passamos, ao menos, 1/3 de nossas vidas em nossos colchões. Nesse período, cerca de mil milhões de ácaros se reproduzem em vosso colchão. Eis então a importância de se realizar uma higienização profissional a cada seis meses.'

export const textoTapetes = 'O processo de lavagem de tapetes contribui para a remoção de resíduos, poeiras e micro organismos vivos presentes em vosso tapete. A peça é recolhida em vossa casa e levada para uma lavandaria especializada e equipada com centrífuga (para garantir a higienização completa) e estufa (para garantir 100% de secagem). Em poucos dias você recebe seu tapete limpo e cheiroso.'

export const textoEstofosPele = 'Os estofos de pele também precisam de cuidados, pois acabam por ressecar ao longo do uso. O tratamento é feito com produtos profissionais, adequados ao tipo de tecido que limpam e hidratam a peça, ajudando a recuperar a saúde e o brilho do vosso estofo.'

export const textoImpermeabilizacao = 'A Very Clean é especializada em proteger estofados, tornando-os hidrofóbicos ou repelentes a líquidos e outros detritos. Utilizamos nanopartículas especiais que deixam as fibras do tecido blindadas, sem alterar características como cor e textura.'

export const textoAlcatifas = 'Somente aspirar as alcatifas de vossa casa ou escritório não é suficiente. As alcatifas com sujidade entranhada podem necessitar de um tratamento mais rigoroso. A VERY CLEAN é especialista na limpeza e higienização profissional de alcatifas, removendo toda a sujidade, nódoas, manchas, ácaros, odores e bactérias, tornando o ambiente muito mais agradável e saudável seja em casa ou no escritório.'

export const textoQuemSomos = `
A Very Clean é uma empresa nascida de uma franquia brasileira de Limpeza e Higienização de estofos e que se tornou realidade cá em Portugal. Atuamos com o mercado de higienização desde 2021 e zelamos pelo bem estar da vossa família. 
Acreditamos que a limpeza de um estofo vai muito além da questão estética. Envolve saúde e bem estar.
Nossa missão é proporcionar saúde aos de vossa casa por meio de um trabalho profissional de cuidados com estofos e itens de decoração em tecido que, ao longo do uso, acabam por acumular ácaros, fungos e bactérias. 
Temos como visão o profissionalismo, o respeito e o cuidado com nosso cliente. Trabalhando sempre como se fosse para nós. 
Nossos valores são comprometimento, honestidade e bons princípios para que nosso atendimento possa te surpreender do início ao fim.
`