// import { useState, useEffect } from 'react'
// import {Card, Button} from 'react-bootstrap'

import styled from 'styled-components'

const Container = styled.div`
  margin: 32px;
  width: 550px;
  height: 600px;
  /* border: 1px solid rgba(0, 0, 0, 0.1); */

  @media only screen and (max-width: 600px) {
    margin: 16px 0px;

  }
`

const Img = styled.img`
  width: 100%;
  height: 300px;

  @media only screen and (max-width: 600px) {
    height: 250px;
  }
`

const CardHeader = styled.span`
  width: 100%;
  min-height: fill-available !important;
  text-align: center;
`

const CardTitle = styled.h3`
  font-size: 20px;
  padding: 16px;
  text-transform: uppercase;
  font-family: 'Mont Bold';

  @media only screen and (max-width: 600px) {
    font-size: 16px;
    padding: 8px;

  }

`

const CardBody = styled.span`
  all: unset;
  width: 100%;
  height: 100px;
`

const CardText = styled.p`
  /* all: unset; */
  text-align: justify !important;
  color: #7e7b7b;
  font-size: 15px;
`

const CardFooter = styled.span`
  all: unset;
  width: 100%;
  height: 20px;
  display: flex;
  justify-content: right;
`

function CardStl(props){

  return (
    <Container>
      <Img src={props.img}/>
      <CardHeader>
        <CardTitle>
          {props.title}
        </CardTitle>
      </CardHeader>
      <CardBody>
        <CardText>
          {props.text}
        </CardText>
      </CardBody>
      <CardFooter>
        {props.CardBtn}
      </CardFooter>
    </Container>
  )

}

export {CardStl}
export default CardStl