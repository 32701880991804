import React from 'react';
import styled from 'styled-components'

import video from '../assets/videos/video1.mp4'

const VideoStyled = styled.video`
  width: 100%;
`

function Video(){

  return (
    <VideoStyled
      autoPlay
      muted
      loop
      src={video}
      id='home'
    />
  )
}

export {Video}
export default Video