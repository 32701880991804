import React from "react";

import { 
  Nav,
  Video,
  ServicesSection,
  AboutSection,
  WhatsAppBtn,
  BriefSection,
  FAQSection,
  MainBanner,
  Footer,
} from "../components";


import "aos/dist/aos.css"

function Home() {

  return (
    <>
    <Nav/>
    <MainBanner/>
    <ServicesSection/>
    <AboutSection/>
    <BriefSection/>
    <FAQSection/>
    <WhatsAppBtn/>
    <Footer/>
    </>
  )
}

export { Home }
export default Home