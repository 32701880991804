import React from 'react'
import styled from 'styled-components'
import { Link } from "react-scroll";

import {RenderIf} from '.'

import { useTamanhoTela } from '../hooks/useTamanhoTela';
import logoDesktop from '../assets/images/logotipo-colorido.png'
import logoMobile from '../assets/images/isotipo-colorido.png'
import instagramLogo from '../assets/images/instagram-logo.png'



const StyledNav = styled.nav`
  position: sticky;
  top: 0;
  z-index: 6;
  height: 13vh;
  /* max-width: 100%; */
  background-color: #fefefe;
  display: flex;
  justify-content: space-between;
  padding: 0 170px;
  border-bottom: 1px solid #0071a8;
  box-shadow: 0px 10px 33px -14px rgba(0, 0, 0, 0.65);

  @media only screen and (max-width: 600px) {
    padding: 0 16px;
  }

  @media only screen and (min-width: 1600px) {
    padding: 0 250px;
  }
`

const Logo = styled.img`
  height: 11vh;
  width: 14vw;
  /* margin-bottom: 16px; */

  @media only screen and (max-width: 600px) {
    height: 80px;
    width: 80px;
  }
`

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* margin-top: 2%; */
`

const StyledLink = styled(Link)`
  margin: 8px;
  color: #0c3c64;
  cursor: pointer;
  text-decoration: none;
  font-size: 18px;
  padding: 0 8px;

  :hover {
    color: #1f68a3;
  }

  @media only screen and (max-width: 600px) {
    font-size: 13px;
    padding: 0px;
  }

  @media only screen and (min-width: 1600px) {
    font-size: 22px;
  }
`

const InstagramLogo = styled.img`
  height: 50px;
  width: 50px;
  margin: 0 60px;
`

function Nav () {
  const {isMobile, isTablet, isDesktop, isDesktopLarge} = useTamanhoTela()

  function getLogo() {
    if(isMobile || isTablet) {
      return logoMobile
    }

    if(isDesktop || isDesktopLarge) {
      return logoDesktop
    }
  }

  return (
    <StyledNav>
      <Container>
        <a href='/'>
          <Logo src={getLogo()}  alt='Logo Very Clean'/>
        </a>
      </Container>

      <Container>
        <StyledLink to='home' offset={-45}>
          Inicio
        </StyledLink>
        <StyledLink to='servicos' offset={-90}>
          Serviços
        </StyledLink>
        <StyledLink to='sobre' offset={-120}>
          Sobre
        </StyledLink>
        <StyledLink to='depoimentos' offset={-45}>
          Depoimentos
        </StyledLink>
      </Container>

      <RenderIf condicao={isDesktop || isDesktopLarge}>      
        <Container>
          <a href='https://www.instagram.com/verycleanpt/' target='_blank'>
            <InstagramLogo src={instagramLogo}/>
          </a>
        </Container>
      </RenderIf>

      


    </StyledNav>
  )
}

export { Nav }
export default Nav
