import React, {useEffect} from 'react'
import styled from 'styled-components'
import { Accordion } from 'react-bootstrap'
import AOS from "aos"
import "aos/dist/aos.css"

import { useTamanhoTela } from '../hooks/useTamanhoTela'


const Section = styled.section`
  width: 100%;
  height: 100%;
  background-color: #fefefe;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: -36px 0px;
  margin-bottom: 256px;
`

const  TitleContainer = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
  width: 100%;
`

// const SectionTitle = styled.h2`
//   /* background-color: #fefefe; */
//   /* width: 30%; */
//   text-align: center;
//   /* border-radius: 12px; */
//   padding: 8px;
//   border-bottom: 2px solid #0c3c64;
//   /* box-shadow: -1px 7px 14px 5px rgba(0,0,0,0.75); */
//   margin-top: ${props => props.mobile ? '0' : '32px'};
//   font-family: 'Mont Bold';
// ` 

const SectionTitle = styled.h2`
  /* background-color: #fefefe; */
  /* width: 30%; */
  color: #0c3c64;
  text-align: center;
  font-size: 96px;

  font-family: 'Mont Bold';

  @media only screen and (max-width: 600px) {
    justify-content: center;
    margin-right: 0px;
    font-size: 70px;

  }
`

const MarkedText = styled.span`
  all: unset;
  background-color: #0d3c64;
  color: #fefefe;
`

const FAQContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 60%;
  margin-top: 64px;
  margin-bottom: 16px;
  
  @media only screen and (max-width: 600px) {
    width: 80%;

  }
`

const AccordionSt = styled(Accordion)`
  width: 100%;
`

const AccordionText = styled(Accordion.Body)`
  font-size: 15px;
  color: #7e7b7b;
`
function FAQSection(){
  const {isMobile, isTablet} = useTamanhoTela()

  useEffect(() => {
    AOS.init({duration: 1000})
  },[])

  return (
    <Section id='faq'>
      <TitleContainer>
        <SectionTitle mobile={isMobile||isTablet}>
          Perguntas 
          <br/>
          <MarkedText>frequentes</MarkedText> 
        </SectionTitle>
      </TitleContainer>

      <FAQContainer
        mobile={isMobile||isTablet}
        data-aos="fade-left"
      >

        <AccordionSt>
        <Accordion.Item eventKey="1">
            <Accordion.Header>
              Com que frequência preciso higienizar meu sofa e meu colchão?
            </Accordion.Header>
            <AccordionText>
              A higienização profissional de sofás e colchões deve ser feita a cada seis meses para garantir a durabilidade da peça e a saúde dos usuários.
            </AccordionText>
          </Accordion.Item>
          <Accordion.Item eventKey="2">
            <Accordion.Header>
              Depois de quanto tempo posso usar novamente meu sofá, colchão ou cadeira?
            </Accordion.Header>
            <AccordionText>
            O processo de secagem completa de um item higienizado leva de 8 a 24 horas. Nesse período, evite sentar na peça que foi higienizada.
            </AccordionText>
          </Accordion.Item>
          <Accordion.Item eventKey="3">
            <Accordion.Header>
                Quais as formas de pagamento?
            </Accordion.Header>
            <AccordionText>
            Dinheiro, transferência, Mbway ou Multibanco
            </AccordionText>
          </Accordion.Item>
          <Accordion.Item eventKey="4">
            <Accordion.Header>
                A higienização tira as manchas?
            </Accordion.Header>
            <AccordionText>
              Existe sim um ótimo resultado neste quesito, ou seja, na extrema maioria
              dos casos as manchas são retiradas, porém nenhuma empresa ou profissional
              sério pode garantir em 100% que retira todas as manchas. Algumas questões
              devem ser levadas em conta, como por exemplo o que causou a mancha
              e o tempo em que ela está no tecido.
            </AccordionText>
          </Accordion.Item>
          <Accordion.Item eventKey="5">
            <Accordion.Header>
                Como é feita  impermeabilização?
            </Accordion.Header>
            <AccordionText>
              Na impermeabilização é usado um produto químico (sílica), que popularmente conhecida como vidro líquido.
              Este produto cria uma película protetora no tecido reduzindo drasticamente  a penetração de sujidade
              e líquidos, desta forma garantindo uma durabilidade maior da peça.
            </AccordionText>
          </Accordion.Item>
        </AccordionSt>

      </FAQContainer>
    </Section>
  )
}

export {FAQSection}
export default FAQSection