import React, {useEffect} from 'react'
import styled from 'styled-components'
import AOS from "aos"
import "aos/dist/aos.css"
import Carousel from 'react-bootstrap/Carousel';

import { useTamanhoTela } from '../hooks/useTamanhoTela'

import { BriefCard } from '.'

import Dep1 from '../assets/images/depoimento1.png'
import Dep2 from '../assets/images/depoimento2.png'
import Dep3 from '../assets/images/depoimento3.png'


const Section = styled.section`
  width: 100%;
  height: 100%;
  background-color: #fefefe ;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: -36px 0px;
  margin-bottom: 256px;
`

const  TitleContainer = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
  width: 100%;
`

// const SectionTitle = styled.h2`
//   /* background-color: #fefefe; */
//   /* width: 30%; */
//   text-align: center;
//   /* border-radius: 12px; */
//   padding: 8px;
//   border-bottom: 2px solid #0c3c64;
//   /* box-shadow: -1px 7px 14px 5px rgba(0,0,0,0.75); */
//   margin-top: ${props => props.mobile ? '0' : '32px'};
//   font-family: 'Mont Bold';
// ` 

const SectionTitle = styled.h2`
  /* background-color: #fefefe; */
  /* width: 30%; */
  color: #0c3c64;
  text-align: center;
  font-size: 96px;

  font-family: 'Mont Bold';

  @media only screen and (max-width: 600px) {
    justify-content: center;
    margin-right: 0px;
    font-size: 56px;

  }
`

const MarkedText = styled.span`
  all: unset;
  background-color: #0d3c64;
  color: #fefefe;
`


const BriefContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 20%;
  margin-top: 64px;
  margin-bottom: 16px;

  @media only screen and (max-width: 600px) {
    width: 80%;
  }
`

const Img = styled.img`
  width: 100%;
  border-radius: 8px 8px 0 0;
`

function BriefSection(){
  const {isMobile, isTablet} = useTamanhoTela()

  useEffect(() => {
    AOS.init({duration: 1000})
  },[])

  return (
    <Section id='depoimentos'>
      <TitleContainer>
        <SectionTitle mobile={isMobile||isTablet}>
          <MarkedText>Depoimentos</MarkedText> 
        </SectionTitle>
      </TitleContainer>

      <BriefContainer
        mobile={isMobile||isTablet}
      >

      <Carousel>
        <Carousel.Item>
          <Img
            // className="d-block w-100"
            src={Dep1}
            /> 
        </Carousel.Item>

        <Carousel.Item>
          <Img
            // className="d-block w-100"
            src={Dep3}
          /> 
        </Carousel.Item>

        <Carousel.Item>
        <Img
          // className="d-block w-100"
          src={Dep2}
        />
        </Carousel.Item>
      </Carousel>



        

        

        

      </BriefContainer>
    </Section>
  )
}

export {BriefSection}
export default BriefSection