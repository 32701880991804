import React from 'react'
import styled from 'styled-components'

import instagramLogo from '../assets/images/instagram-logo-colorida.png'
import Whats from '../assets/images/logo_whats_colorida.png'
import Face from '../assets/images/facebook-logo.png'
import DAVS from '../assets/images/davs.png';


const FooterStyled = styled.footer`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40%;
  background-color: #0c5088;

  @media only screen and (max-width: 600px) {
    flex-direction: column;
    justify-content: center;

  }
`

const FooterStyledDavs = styled.footer`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 5%;
  background-color:#0c3c64;

  @media only screen and (max-width: 600px) {
    flex-direction: column;
    justify-content: center;

  }
`

const FooterSection = styled.section`
  width: 25%;
  height: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding: 64px;

  @media only screen and (max-width: 600px) {
    flex-direction: column;
    justify-content: center;
    padding: 16px 0px;
    width: 100%;


  }
`

const FooterSectionDavs = styled.section`
  width: 100%;
  height: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding: 32px;

  @media only screen and (max-width: 600px) {
    flex-direction: column;
    justify-content: center;
    padding: 16px 0px;
    width: 100%;
  }
`

const SectionTitle = styled.h4`
  font-size: 22px;
  width: 100%;
  color: #fefefe;
`

const Link = styled.a`
  all: unset;
  color: #fefefe;
  font-size: 14px;
  width: 100%;
  cursor: pointer;
`

const Paragraf = styled.p`
  all: unset;
  color: #fefefe;
  font-size: 14px;
  width: 100%;
`

const InstagramLogo = styled.img`
  height: 50px;
  width: 50px;
  margin: 0 8px;
`

const WhatsIcon = styled.img`
  width: 45px;
  height: 45px;
  margin: 0 8px;
`

const FaceIcon = styled.img`
  width: 35px;
  height: 35px;
  margin: 0 8px;
`

const TextDavs = styled.a`
  font-size: 18px;
  color: #227ec9;
  cursor: pointer;
  text-decoration: none;
  display: flex:
  align-items: center;
  justify-content: center;
  transition: 300ms;

  :hover {
    color: #fefefe;
  }
`

function Footer(){
  return(
    <>
      <FooterStyled>

        <FooterSection>
          <SectionTitle>
            Contacto
          </SectionTitle>
          <Link
            href="tel:+351914263236"
          >
             +351 914 263 236
          </Link>
          <Link
            href="mailto:veryclean.hig@gmail.com"
          >
            veryclean.hig@gmail.com
          </Link>
        </FooterSection>

        <FooterSection>
          <SectionTitle>
            Horário de funcionamento
          </SectionTitle>
          <Paragraf>
            Segunda à Sexta - 9h às 19h
          </Paragraf>
          <Paragraf>
            Sábado - 9h às 12h
          </Paragraf>
        </FooterSection>

        <FooterSection>
          <SectionTitle>
            Atendimento
          </SectionTitle>
          <Paragraf>
            Distrito de Santarém
          </Paragraf>
          <Paragraf>
            Distrito de Leiria
          </Paragraf>
        </FooterSection>

        <FooterSection>
          <SectionTitle>
          Nossas redes sociais
          </SectionTitle>
          <Paragraf>

            <a href='https://www.instagram.com/verycleanpt/' target='_blank'>
              <InstagramLogo src={instagramLogo}/>
            </a>

            <a
              target='_blank' 
              href='https://wa.me/message/RCV4UXG46YG5A1'
            >
              <WhatsIcon src={Whats}/>
            </a>

            <a
              target='_blank' 
              href='https://www.facebook.com/profile.php?id=100088964347295'
            >
              <FaceIcon src={Face}/>
            </a>

          </Paragraf>


        </FooterSection>

      </FooterStyled>

      <FooterStyledDavs>
        <FooterSectionDavs>
          <TextDavs href="https://api.whatsapp.com/send?phone=+5584998545520&text=Olá,%20gostaria%20de%20pedir%20um%20orçamento." target="__blank">Desenvolvido por <img src={DAVS} width="32px" height="32px"/></TextDavs>
        </FooterSectionDavs>
      </FooterStyledDavs>
    </>
  )
}

export {Footer}
export default Footer